
$black: #000000;
$white: #FFFFFF;
$light-grey: #f7f7f7;
$grey-light2: rgba(0, 0, 0, .125);
$grey: #cccccc;
$grey-medium: #9c9c9c;
$black-disabled: rgba(0, 0, 0, 0.17);

$dark-blue: #1a2226;
$dark-blue-light: #222d32;
$blue-light: #b8c7ce;

$red: #ce3d35;
$dark-orange: #ed7a37;
$orange: #ef7a37;
$orange-light: #ea9363;

$green-hardsoft: rgb(233 255 233);
$green-soft2: #cbedc3;
$green-soft: #61b651;
$green-ccpae: #74b201;
$green: #1aa100;
