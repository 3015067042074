
// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
// Plus imports for other modules in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$gae-fe-primary: mat.define-palette(mat.$indigo-palette);
$gae-fe-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$gae-fe-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$gae-fe-theme: mat.define-light-theme((
  color: (
    primary: $gae-fe-primary,
    accent: $gae-fe-accent,
    warn: $gae-fe-warn,
  )
));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($gae-fe-theme);

/* You can add global styles to this file, and also import other style files */

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

/* Importing Bootstrap SCSS file. */
@import "bootstrap/scss/bootstrap";
@import "scss/colors";

.badge {
  --bs-badge-font-size: 0.9em
}

.mat-raised-button.mat-primary, .mat-mini-fab.mat-primary {
  background-color: #06562a;
}

.mat-raised-button.mat-basic, .mat-mini-fab.mat-basic {
  background-color: #cbedc3;
}

.mat-raised-button.mat-cancel, .mat-mini-fab.mat-cancel {
  background-color: red;
}

.mat-raised-button.mat-cancel-basic, .mat-mini-fab.mat-cancel-basic {
  background-color: #EDC3C3;
}

.font-weight-bold {
  font-weight: bold;
}

::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: $green-ccpae;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.clickable {
  cursor: pointer;
}

// Custom mat-expansion
.mat-expansion-panel {
  margin: 10px!important;
  background-color: #f1f1f1;
}

mat-panel-title {
  font-weight: 420;
}

.mat-card-title {
  position: absolute;
  border: 1px solid #cfcfcf;
  border-bottom: none;
  background-color: #525252;
  width: 300px;
  max-height: 26px;
  padding: 3px 25px;
  border-radius: 10px 10px 0px 0px;
  color: white;
  font-weight: 420;
  top: -27px;
  font-size: 14px;
}

.mat-card:has(.mat-card-title) {
  margin-top: 40px;
}

.mat-step-header .mat-step-icon-selected, .mat-step-header .mat-step-icon-state-done, .mat-step-header .mat-step-icon-state-edit {
  background-color: #188930;
  color: white;
}

.cdk-global-overlay-wrapper {
  display: flex;
  position: absolute;
  z-index: 1000;
  overflow: auto;
  pointer-events: auto;
}

.toast-success {
  background-color: #c0e9b6;
  color: #000000;

  .mat-button {
    color: black;
  }
}

.toast-error {
  background-color: #e89564;
  color: #000000;

  .mat-button {
    color: black;
  }
}

.test-back {
  background-color: greenyellow!important;
}

.test-back2 {
  background-color: firebrick!important;
}
